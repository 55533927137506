import {
  isBlank,
  isObject,
  isString,
  isUndefined,
} from '@iheartradio/web.utilities';
import { createEmitter } from '@iheartradio/web.utilities/create-emitter';
import jwtDecode from 'jwt-decode';

import type {
  IdResponse,
  OauthLinkActionBody,
  OauthLoginActionBody,
  RequestCodeResponse,
} from './types';

const buildGooglePost = async (
  code: string,
  type: 'link' | 'login',
): Promise<OauthLoginActionBody | OauthLinkActionBody | null> => {
  const tokenFormData = new FormData();
  tokenFormData.append('code', code);

  const tokenRequest = await fetch('/api/v1/google/exchange-code', {
    method: 'post',
    body: tokenFormData,
  });

  if (tokenRequest.status === 200) {
    const { access_token, id_token }: RequestCodeResponse =
      await tokenRequest.json();
    const decoded = jwtDecode<IdResponse>(id_token);

    if (decoded.sub) {
      if (type === 'login') {
        const postBody: OauthLoginActionBody = {
          type: 'checkAccount',
          accessToken: access_token,
          accessTokenType: 'google',
          oauthUuid: decoded.sub,
        };
        if (decoded.email) {
          postBody.userName = decoded.email;
        }
        return postBody;
      } else {
        const postBody: OauthLinkActionBody = {
          intent: 'link',
          accessToken: access_token,
          accessTokenType: 'google',
          oauthUuid: decoded.sub,
        };
        return postBody;
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const googleSdkFactory = ({
  onError = () => {
    console.log('Error with Google SDK');
  },
  onLink,
  onLogin,
  onUnlink,
}: {
  onError?: (initialization?: boolean) => void;
  onLink?: (postBody: OauthLinkActionBody) => void;
  onLogin?: (postBody: OauthLoginActionBody) => void;
  onUnlink?: () => void;
}) => {
  let loginCodeClient: ReturnType<typeof google.accounts.oauth2.initCodeClient>;
  let linkCodeClient: ReturnType<typeof google.accounts.oauth2.initCodeClient>;

  const generateCodeClients = (appKey: string) => {
    if (isUndefined(window?.google)) {
      return;
    }
    if (onLogin) {
      loginCodeClient = google?.accounts.oauth2.initCodeClient({
        client_id: appKey as string,
        callback: async response => {
          if (!isObject(response) || !isString(response.code)) {
            return;
          }

          try {
            const postBody: OauthLoginActionBody | null =
              (await buildGooglePost(
                response.code,
                'login',
              )) as OauthLoginActionBody;

            if (postBody) {
              onLogin(postBody);
            } else {
              onError(false);
            }
          } catch (error_) {
            const error =
              error_ instanceof Error ? error_ : new Error('Unknown error');
            onError(false);
            console.error(error.message);
          }
        },
        scope: 'profile email openid',
      });
    }
    if (onLink) {
      linkCodeClient = google?.accounts.oauth2.initCodeClient({
        client_id: appKey as string,
        callback: async response => {
          if (!isObject(response) || !isString(response.code)) {
            return;
          }

          try {
            const postBody: OauthLinkActionBody | null = (await buildGooglePost(
              response.code,
              'link',
            )) as OauthLinkActionBody;

            if (postBody) {
              onLink(postBody);
            } else {
              onError(false);
            }
          } catch (error_) {
            const error =
              error_ instanceof Error ? error_ : new Error('Unknown error');
            onError(false);
            console.error(error.message);
          }
        },
        scope: 'profile',
      });
    }
  };

  const emitter = createEmitter({
    initialize(appKey: string | undefined) {
      return new Promise((resolve, reject) => {
        if (isBlank(appKey)) {
          onError(true);
          reject(new Error('App key missing for Google SDK'));
          return;
        }
        if (document.querySelector('script#google-sdk')) {
          generateCodeClients(appKey as string);
          resolve(true);
          return;
        }

        const googleScript = document.createElement('script');
        googleScript.id = 'google-sdk';
        googleScript.src = 'https://accounts.google.com/gsi/client';
        googleScript.async = true;
        googleScript.defer = true;

        googleScript.addEventListener('error', () => {
          onError(true);
          reject(new Error('Error loading Google SDK'));
        });
        googleScript.addEventListener('load', () => {
          generateCodeClients(appKey as string);
          resolve(true);
        });
        document.body.append(googleScript);
      });
    },
    link() {
      if (linkCodeClient) {
        linkCodeClient.requestCode();
      }
    },
    login() {
      if (loginCodeClient) {
        loginCodeClient.requestCode();
      }
    },
    unlink() {
      if (onUnlink) {
        onUnlink();
      }
    },
    remove() {
      document.querySelector('script#google-sdk')?.remove();
    },
  });

  return emitter;
};
